import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <h1>Hi</h1>
    <p>You probably came here because you intended to reach us. That was foolish. This is clearly a joke website. Please don't try to talk to me.</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
